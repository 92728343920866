<template>
  <div class="digitalFlopBox">
    <div class="card_box">
      <div class="card" :style="{backgroundImage: 'url(' + require('@/assets/num-bg.png') + ')' }" v-for="(itemNum, ind) in Num.numList" :key="ind">
        <div  ref="spanRefs" class="card_item">
          <div v-for="(item,index) in 10" style="height:fit-content">
            {{index}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'MobileNumScroll',
  props:{
    Num: {
      type: Object,
      default: function () {
        return {
          numList: [0, 0, 0, 0, 0, 0],
        }
      }
    }
  },
  watch:{
    'Num.numList':function (newVal, oldVal){
      if(Array.isArray(this.$refs.spanRefs)){
        this.$refs.spanRefs.map((item, index) => {
          const span = this.$refs.spanRefs[index];
          if (span) {
            setTimeout(() => {
              span.style.transform = `translateY( -${newVal[index] * 10}%)`
            }, index*500)
          }
        })
      }
    }
  },
};
</script>

<style scoped>
.card_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 1.8125rem;
  height:2.375rem;
  line-height:2.375rem;
  color: #FFEEAD;
  text-align: center;
  background-size: 100% 100%;
  font-size: 1.875rem;
  margin-left: 0.25rem;
  overflow: hidden;
  font-family: Times New Roman;
}
.card_item{
  transition: transform 1s ease-in-out;
}
</style>