<template>
  <div class="flex-col page" @touchmove="handleScroll">
      <div class="flex-col group" style="margin-top: 3rem">
        <div class="flex-col space-y-14 items-center" style="margin-top: 3rem">
          <div>
            <span class="font_1">跨境融资&emsp;一触</span>
            <span class="font_1 text">即达</span>
          </div>
          <div class="flex-row group_3">
            <span class="font_2">10000借一天仅1.8元起</span>
          </div>
        </div>
        <div style="background-image: linear-gradient(341.2deg, #ffffff 18.2%, #697de4 140.3%);border-radius: 0.75rem 0.75rem 0 0;">
          <div class="flex-col group_4" >
            <el-input size="middle"  v-model="monthlyPrice" placeholder="请输入您的月平均销售额"  class="month_input"></el-input>
            <span class="self-center font_2 text_4">您将获得</span>
            <span class="self-center text_5">≈￥{{$utils.PriceFormat(loanLimit)}}</span>
            <el-button class="main_btn2" style="border-radius:0.25rem;font-size:0.875rem;line-height:2.5;margin-top: 0.75rem;color:#161616;background-image: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);" @click="$router.push({path:'/home/apply'})" >我要申请</el-button>
            <div style="text-align: center;margin-top:1rem">
              <img class="image_mercado_logo" :src="require('@/assets/mercadoLogo.png')" alt="美客多Logo"/>
            </div>
          </div>
          <div style="text-align: center">
            <img
                style="width: 100%;margin-top: 3.125rem;"
                :src="require('@/assets/mobile_bg.png')"
            />
          </div>
        </div>
        </div>

    <div class="flex-col">
      <div class="flex-col justify-start relative">
        <div class="flex-col section space-y-22" >
          <div class="font_1 self-center">麦嘉宝</div>
          <span class="self-center font_2 text_6">致力为卖家提供安全、专业、合规的跨境金融服务</span>
          <div class="flex-col justify-start relative">
            <div class="grid" >
              <div class="flex-col items-center grid-item preponderanceList" style="opacity:0;" v-for="item in preponderanceList">
                <img
                    class="image_8"
                    :src="item.url"
                />
                <span class="font_4 text_7">{{item.title}}</span>
                <span class="font_6 text_8">{{item.content}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_3 space-y-34 loans_bg" :style="{backgroundImage: 'url(' + require('@/assets/loans_bg.png') + ')'}">
        <span class="self-center font_3 text_10">卓越的产品方案与服务</span>
        <div class="flex-col space-y-19">
          <div class="flex-col space-y-12">
            <div class="flex-col list-item ServerList" style="opacity:0" v-for="(item, index) in ServerList" :key="index">
              <div class="flex-col items-start section_4 space-y-10" style="position: relative;overflow: hidden">
                <span class="font_7">{{item.title}}</span>
                <div class="font_8 text_11" style="width: 85%;">{{item.content}}</div>
                <span class="self-end font_9 text_12 text_13" style="position: absolute;right: 0;bottom: -1.125rem">0{{index+1}}</span>
              </div>
            </div>
          </div>
          <span class="self-center font_4 text_16">已成功为卖家提供资金</span>
            <MobileNumScroll :Num="Num"></MobileNumScroll>
        </div>
      </div>
    </div>
    <div class="flex-col">
      <div class="flex-col section_8 space-y-33 items-center">
        <span class="self-center font_3 text_18">战略合作伙伴</span>
        <vue-seamless-scroll
            :data="[1,2,3,4]"
            :class-option="classOption1"
            class="warp"
            style="width:1px"
        >
            <div class="flex-row">
              <img :src="require('@/assets/SPDBANK.png')" style="width:7.5rem;height:2rem;" class="img_bank">
              <img :src="require('@/assets/SHANGHAIBANK.png')" style="width: 6.25rem;height: 2rem" class="img_bank">
              <img :src="require('@/assets/CCB.png')" style="width: 8rem;height: 2rem" class="img_bank">
              <img :src="require('@/assets/CHINAMERCHANTSBANK.png')" style="width: 8.25rem;height: 2rem" class="img_bank">
            </div>
        </vue-seamless-scroll>
            <vue-seamless-scroll
                :data="[1,2,3,4,5]"
                :class-option="classOption2"
                class="warp"
                style="width:1px"
            >
              <div class="flex-row" style="width: 48.875rem">
                <img :src="require('@/assets/ICBC.png')" style="width: 10.375rem;height: 2rem" class="img_bank"/>
                <img :src="require('@/assets/BANKOFCHINA.png')" style="width: 6.875rem;height: 2rem" class="img_bank "/>
                <img :src="require('@/assets/AgriculturalBank.png')" style="width: 10.25rem;height: 2rem" class="img_bank"/>
                <img :src="require('@/assets/BEIJINGBANK.png')" style="width: 9.325rem;height: 2rem" class="img_bank"/>
                <img :src="require('@/assets/PINGANBANK.png')" style="width: 6.125rem;height: 2rem" class="img_bank"/>
              </div>
            </vue-seamless-scroll>
      </div>
      <div class="flex-col section_9">
        <div class="flex-row items-center space-x-4"  style="margin-bottom: 1.25rem">
          <img
              class="image_16"
              :src="require('@/assets/logo.png')"
          />
          <img
              class="image_17"
              :src="require('@/assets/white_MAKAFIM.png')"
          />
        </div>
        <div style="display: flex">
          <div class="flex-col flex-auto" style="width: 70%">
            <span class="font_8 text_19" style="margin-bottom:1.875rem">为跨境企业提供全方位、高效、安全的跨境金融服务</span>
            <el-button class="main_btn2" style="font-size:0.875rem;line-height:2.375;width:80%;border:0;color:#161616; background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);border-radius: 0.25rem;" @click="$router.push({path:'/home/apply'})">我要申请</el-button>
          </div>
          <img
              class="shrink-0 image_18"
              style="align-self: center"
              :src="require('@/assets/QR_code.png')"
          />
        </div>
        <div class="flex-col justify-start items-start image-wrapper">
          <div style="font-size:0.625rem;color:#FFFFFF">版权所有@麦嘉Maka<a class="aq" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2023119718号-1</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileNumScroll from "@/components/num/MobilenumScroll.vue";
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  props: {},
  components:{
    MobileNumScroll,
    vueSeamlessScroll
  },
  data() {
    return {
      classOption1: {
        step:3,
        hoverStop:false,
        isSingleRemUnit:true,
        limitMoveNum: 4,
        direction: 2,
      },
      classOption2: {
        step:2,
        hoverStop:false,
        isSingleRemUnit:true,
        limitMoveNum: 5,
        direction: 2,
      },
      //卓越客户服务
      ServerList: [
        {title:'强大的数据分析能力', content:'通过数据分析技术，为客户提供更加精准的金融服务，同时也能为客户的未来决策提供参考'},
        {title:'综合金融服务', content:'为跨境电商卖家提供多元化、定制化的综合金融服务，帮助跨境企业获得更多融资机会'},
        {title:'完善的风控体系', content:'拥有完善的风控体系，降低不良资产率，科学合理解决电商卖家的账期需求'},
        {title:'快捷、便利', content:'跨境应收账款融资服务具有快捷、便利的特点，用户能够快速地完成支付操作'}
      ],
      //输入额度
      monthlyPrice:'',
      //优势
      preponderanceList:[
        {url:require('@/assets/fast.png'),title:'快速',content:'快速完成跨境业务资金融资'},
        {url:require('@/assets/safe.png'),title:'安全',content:'确保企业交易的安全性和合规性'},
        {url:require('@/assets/specialty.png'),title:'专业团队',content:'团队具有丰富的金融和风险管理经验'},
        {url:require('@/assets/diversification.png'),title:'多样化',content:'多种金融服务，满足企业不同的金融需求'},
      ],
      //资金滚动数据
      Num:{
        numList: [],
        flag: true
      },
      loansMoney:0,
    };
  },
  computed: {
    loanLimit:function (){
      let monthlyPrice=this.monthlyPrice || 0
      let price=this.$utils.calculate.multiply(monthlyPrice,1.5)
      if(price>10000000){
        price=10000000
      }else if(price<0){
        price=0
      }else{
        price=parseInt(price)
      }
      return price
    }
  },
  mounted() {
    // 获取需要监听的元素
    this.elements1 = document.querySelectorAll('.preponderanceList');
    this.elements2 = document.querySelectorAll('.ServerList');
    //获取滚动器
    this.elements3 = document.querySelector('.digitalFlopBox');

  },
  activated() {
    this.GetNum()
  },
  methods: {

    //数字滚动函数
    ChangeNum(num){
      this.Num.numList = num.toString().split('')
    },
    //获取资金
    GetNum(){
      this.$api.post('/v1/api/makafin/index',{},res=>{
        this.loansMoney=res.makafin_money
        let sessionMoney=this.$utils.CacheSessionGet('moneyloans')
        if(sessionMoney){
          this.Num.numList =sessionMoney.toString().split('')
        }else{
          this.Num.numList = new Array(res.makafin_money.toString().split('').length).fill(0)
        }
        this.$utils.CacheSessionSet('moneyloans',res.makafin_money)
      })
    },
    handleScroll(e){
      // 获取需要监听的元素
      this.AnimateFun(this.elements1,'animate__backInRight')
      this.AnimateFun(this.elements2,'animate__zoomIn')
      const Top=this.elements3.getBoundingClientRect().top
      if(Top<window.innerHeight){
        this.ChangeNum(this.loansMoney)
      }
    },
    /**
     * 动画
     * @param el 元素
     * @param type 动画类型
     */
    AnimateFun(el,type){
      // 对于每一个需要监听的元素
      el.forEach((element,index) =>{
        // 获取元素进入视野范围的位置
        const elementTop = element.getBoundingClientRect().top;
        // 判断是否进入视野范围
        if (elementTop< window.innerHeight ) {
          // 添加需要的动画类名
          setTimeout(()=>{
            element.style.opacity=1
            element.classList.add('animate__animated',type);
          },index*500)
        }else{
          element.classList.remove('animate__animated',type);
        }
      });
    }
  },
};
</script>
<style>
.animate_bank{
  width: 100%;
  overflow: hidden;
  animation: run linear 5s infinite;
}
@keyframes run {
  0%{
    transform: translate(0%);
  }
  100%{
    transform: translate(-100%);
  }
}
.img_bank{
  margin-right: 1.5rem;
}
.aq{
  margin-left: 1rem;
  color: #FFFFFF;
  text-decoration: none;
}
.aq:hover{
  text-decoration: underline;
}
.preponderanceList:nth-child(1){
  border-right: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
}
.preponderanceList:nth-child(2){
  border-left: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
}
.preponderanceList:nth-child(3){
  border-right: 1px solid #EFEFEF;
  border-top: 1px solid #EFEFEF;
}
.preponderanceList:nth-child(4){
  border-left: 1px solid #EFEFEF;
  border-top: 1px solid #EFEFEF;
}
.month_input{
  margin-top: 3.875rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.month_input .el-input__inner{
  height: 2.5rem;
  font-size: 0.75rem;
  text-align: center;
}
</style>
<style scoped>
.loans_bg{
  background-size: 100% 100%;
  align-items: center
}
.image_mercado_logo{
  width: 7.2rem;
  height: 2rem;
}
.font_9 {
  font-size: 3rem;
  font-family: Microsoft YaHei;
  color: #ffffff;
}
</style>